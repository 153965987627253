.select2-container--default .select2-selection--single, 
.select2-container--default.select2-container--disabled .select2-selection--single, 
.select2-container--default .select2-selection--multiple, 
.select2-container--default.select2-container--disabled .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field {
	//background: #383b40;
	color: #ffffff;
    background-color: rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.35);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #ffffff;
}


.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple, 
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--open .select2-dropdown--above,
.select2-container--open .select2-dropdown--below {
	background: #383b40;
}


.select2-container--disabled .select2-selection.select2-selection--single,
.select2-container--disabled .select2-selection.select2-selection--multiple {
	background-color: #3f4246;
	border-color: rgba(132, 132, 132, 0.35);
}
